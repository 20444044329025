import './About.scss';

function About() {
    return (
        <section className="page-section bg-white" id="about">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-lg-4 col-md-12 col-xl-4  col-sm-12 col-12">
                        <h2 className="mr-1 title font-weight-bolder">About our place and mission</h2>
                    </div>
                    <div className="col-lg-6 col-md-12 col-xl-6  col-sm-12 col-6=12  text ">
                        <p className="pb-2">Since 2017 <b className="text-danger"> UAB Detales plius</b>  is dealing in two different markets: automotive and cosmetic.
                        </p>
                        <p className="pb-2">As one of the shareholders of Global One Automotive GmbH, UAB Detales plius has been able to
                            improve the quality of the services, increase its competitiveness, and have a strong track
                            record of advanced marketing performance, development and work efficiency in the automotive
                            aftermarket.
                        </p>
                        <p className="pb-2"> With 1,700 different UV gels and endless passion: we’ve been distributing modern,
                            industry-leading products for the cosmetic nail industry.</p>

                    </div>

                    {/*</div>*/}
                </div>
            </div>
        </section>
    );
}

export default About;
