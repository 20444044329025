
import './Automotive.page.scss';
import AutomotiveSection from "./components/AutomotiveSection/AutomotiveSection";
import BusMarketSection from "./components/BusMarketSection/BusMarketSection";
import Contacts from "../../components/Contacts/Contacts";
import SolgySection from "./components/SolgySection/SolgySection";
import GlobalOneSection from "./components/GlobalOneSection/GlobalOneSection";

function AutomotivePage() {
    return (
        <div className="automotive-container">
            <AutomotiveSection></AutomotiveSection>
            <GlobalOneSection></GlobalOneSection>
            <BusMarketSection></BusMarketSection>
            <SolgySection></SolgySection>

        </div>
    );
}

export default AutomotivePage;
