
import './Home.page.scss';
import MainSection from "./components/MainSection/MainSection";
import About from "./components/About/About";
import Contacts from "../../components/Contacts/Contacts";

function HomePage() {
    return (
        <div className="home-container">
            <MainSection></MainSection>
            <About></About>

        </div>
    );
}

export default HomePage;
