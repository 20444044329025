import './BusMarketSection.scss';

function BusMarketSection() {
    return (
        <section className="page-section busmarket-section " id="busmarket">
            <div className="container">
                <div className="row justify-content-center">

                    <div className="col-12 mb-4">
                        <h2 className="mr-1 title font-weight-bolder">BusMarket Group TM</h2>
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 mb-sm-4">
                        <p className="pb-2">
                            UAB Detales plius is a shareholder of LTD DAG. LTD DAG is a part of busmarket group (<a href="https://dag.in.ua">https://dag.in.ua</a>)
                        </p>
                        <p className="pb-2">A BusMarket group is Ukraine’s #1 LCV parts distributor and one of the leading companies in
                            the Ukrainian aftermarket.
                        </p>
                        <p className="pb-2"> Wide and varied product range meets virtually all automotive needs of both passenger
                            cars and light commercial vehicles in Ukraine, offering premium quality products as well as
                            more affordable alternatives, to
                            suit all sorts of requirements.</p>

                        <a className="btn btn-dark btn-xl js-scroll-trigger m-2" href="https://dag.in.ua/">dag.in.ua</a>

                        <a className="btn btn-dark btn-xl js-scroll-trigger" href="https://busmarket.group/">busmarket.group</a>
                    </div>

                    <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4">
                        <div className="card p-3">
                            {/*<img  src="../../../../assets/images/bm_logo_gray.svg" width="250"/>*/}
                            <div className="w-100 p-3">
                                <div>
                                    <h5>Email </h5>
                                    <p>14 Rankova Street, Lutsk</p>
                                </div>
                                <div>
                                    <h5>Address</h5>
                                    <p>info@busmarket.group</p>
                                </div>

                                <div>
                                    <h5>Phone</h5>
                                    <p>+38 (096) 647-77-11</p>
                                    <p>+38 (095) 647-77-11</p>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-12 gallery mt-5">
                            <div className="gallery-item"><img  src="../../../../assets/images/bm_images/1.jpg" alt="" className="logo"/></div>
                            <div className="gallery-item"><img  src="../../../../assets/images/bm_images/2.jpg" alt="" className="logo"/></div>
                            <div className="gallery-item"><img  src="../../../../assets/images/bm_images/3.jpg" alt="" className="logo"/></div>
                            <div className="gallery-item"><img  src="../../../../assets/images/bm_images/4.jpg" alt="" className="logo"/></div>
                            <div className="gallery-item"><img  src="../../../../assets/images/bm_images/5.jpg" alt="" className="logo"/></div>
                            <div className="gallery-item"><img  src="../../../../assets/images/bm_images/6.jpg" alt="" className="logo"/></div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default BusMarketSection;
