import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './App.scss';
import HomePage from "./pages/Home.page/Home.page";
import Navigation from "./components/Navigation/Navigation";
import AutomotivePage from "./pages/Automotive.page/Automotive.page";

import Footer from "./components/Footer/Footer";
import Contacts from "./components/Contacts/Contacts";

function App() {
  return (
    <div className="container-fluid p-0 m-0">
      <Navigation></Navigation>
        <Router>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/automotive" component={AutomotivePage} />
            </Switch>
        </Router>
        <Contacts></Contacts>
        <Footer></Footer>
    </div>
  );
}

export default App;
