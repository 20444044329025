import './Navigation.scss';
import { useEffect, useState } from "react";



function Navigation() {

    const [active, setActive] = useState(false);


    useEffect(() => {
        const header = document.getElementById("mainNav");
        const sticky = header.offsetTop;
        const scrollCallBack = window.addEventListener("scroll", () => {
            if (window.pageYOffset > sticky) {
                header.classList.add("dark");

            } else {
                header.classList.remove("dark");
            }
        });
        return () => {
            window.removeEventListener("scroll", scrollCallBack);
        };
    }, []);


    return (
        <nav className="navbar navbar-expand-lg  navbar-dark fixed-top py-3" id="mainNav" >

            <div className="container">
                <a className="navbar-brand js-scroll-trigger" href="/">
                    <img src="../assets/images/logo.png" alt="" height="35"/>
                </a>

                <button className="navbar-toggler navbar-toggler-right " type="button" data-toggle="collapse"
                    onClick={(event)=>{setActive(!active)}}
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>


                <div className={`navbar-dark navbar-right navbar-collapse-lg ${active ? "show" : ""}`} id="navbarResponsive">
                    <ul className="navbar-nav ml-auto my-2 my-lg-0">
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="/automotive">Automotive</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contacts">Contacts</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;



