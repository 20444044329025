import './Contacts.scss';

function Contacts() {
    return (
        <section className="page-section bg-white" id="contacts">

            <div className="container space-2 space-lg-3">

                <div className="w-md-80 w-lg-50 text-left mx-md-auto mb-5 mb-md-9">
                    <h2>Contact Us</h2>
                </div>



                <div className="row col-12">
                    <div className="col-lg-5 col-md-12 col-xl-5  col-sm-12 col-12 mb-sm-5 mb-5">

                        <h5 className=" mb-2">
                            UAB "Detalės Plius"
                        </h5>

                        <p className=" mb-2">
                            <a href="mailto:labas@detalesplius.lt">labas@detalesplius.lt
                            </a>
                        </p>

                        <p className=" mb-2">
                            Rokiškio r.sav.Kavoliškio k.Melioratorių g.8A-13
                        </p>



                        <p className=" mb-2">
                            Kodas 304513850
                        </p>



                    </div>
                    <div className="col-lg-7 col-md-12 col-xl-7  col-sm-12 col-12">
                        <form className="contact-form p-5 mb-5" noValidate="novalidate">
                            <div className="row">

                                <div className="col-sm-6 mb-4">
                                    <div className="js-form-message">
                                        <label className="input-label">Your name</label>
                                        <input type="text" className="form-control" name="name" placeholder=""
                                               aria-label="Jeff Fisher" required="" data-msg="Please enter your name."/>
                                    </div>
                                </div>


                                <div className="col-sm-6 mb-4">
                                    <div className="js-form-message">
                                        <label className="input-label">Your email address</label>
                                        <input type="email" className="form-control" name="email"

                                               required="" data-msg="Please enter a valid email address."/>
                                    </div>
                                </div>



                                <div className="col-sm-6 mb-4">
                                    <div className="js-form-message">
                                        <label className="input-label">Subject</label>
                                        <input type="text" className="form-control" name="subject"
                                                required=""/>
                                    </div>
                                </div>


                                <div className="col-sm-6 mb-4">
                                    <div className="js-form-message">
                                        <label className="input-label">Your phone number</label>
                                        <input type="number" className="form-control" name="phone"
                                               aria-label="1-800-643-4500" required=""
                                               />
                                    </div>
                                </div>

                            </div>


                            <div className="js-form-message mb-6">
                                <label className="input-label"></label>
                                <div className="input-group">
                                <textarea className="form-control" rows="4" name="text"
                                          placeholder="..."
                                     ></textarea>
                                </div>
                            </div>


                            <div className="text-center mt-5">
                                <button type="submit" className="btn btn-dark btn-wide transition-3d-hover mb-4">Submit
                                </button>

                            </div>
                        </form>
                    </div>
                </div>


            </div>

        </section>
);
}

export default Contacts;
