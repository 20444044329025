import './GlobalOneSection.scss';

function GlobalOneSection() {
    return (
        <section className="globalone-section bg-white" id="globalone">
            <div className="container ">
                <div className="row justify-content-center ">

                    <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6  mr-1  mb-4 text">
                        <h2 className="mr-1 title font-weight-bolder mb-5">GlobalOne Automotive</h2>
                        {/*<p>The global automotive alliance</p>*/}

                        <p className="pb-2 text-left ">Global One shareholder since 2017.
                            Global one automotive is an acknowledged strategic alliance
                            of internationally leading and progressive automotive
                            aftermarket distributors – in fifteen countries around the
                            world.
                        </p>
                        <h2>SUPPLIERS</h2>
                        <p className="pb-2 text-left">The basis of GOA supplier management are long-term
                            partnerships and close cooperation with suppliers. There are
                            established selective partnerships with most of the OE
                            manufacturers and leading brands.
                        </p>
                        <h2>STRATEGIC SUPPLIERS</h2>
                        <p className="pb-2 text-left"> GOA strategic suppliers are global acting OE suppliers and
                            global leading brands.</p>

                        <a className="btn btn-dark btn-xl js-scroll-trigger" href="//global-one.com">global-one.com</a>

                    </div>

                    <div className="col-12 col-sm-12 col-md-4 col-lg-6 col-xl-6 mr-1   d-flex justify-content-center">
                        <img  src="../../../../assets/images/globalone_shareholder.svg" width="75%"/>
                    </div>

                    <div className="col-12 suppliers mt-5">
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/ATE-Logo.jpeg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/DRiV_Logo_Corp.png" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/Exide_Logo_blue.png" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/KYB.png" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_bilsteingroup.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_bosal.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_bosch.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_contitech.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_gkn.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_hella.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_hepu.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_lesjoefors.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_lumileds.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_mahle.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_mannfilter.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_ngkntk.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_nrf.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_ntnsnr.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_osram.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_schaeffler.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_skf-neu.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_sogefi.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/logo_victorreinz.svg" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/valeo1.png" alt="" className="logo"/></div>
                        <div className="sypplier"><img  src="../../../../assets/images/logo_globalone/zf-trw-stretigc-partner-global-one.svg" alt="" className="logo"/></div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default GlobalOneSection;
