
import './Footer.scss';

function Footer() {
    return (
        <footer className="bg-light py-5">
            <div className="container">
                <div className="small text-center text-muted">Copyright © 2020 - UAB Detales Plius</div>
            </div>
        </footer>
    )
};
export default Footer;
