import './MainSection.scss';

function MainSection() {
    return (
        <header className="masthead position-relative">
            <div className="container h-100 ">
                <div className="row h-100 align-items-center justify-content-start ">
                    <div className="col-lg-7  text-left align-content-lg-start">
                        <span className="label mb-2">DETALĖS PLIUS</span>
                        <h1 className="text-white font-weight-bold">Professional, young and dynamic team</h1>
                        <p className="text-white font-weight-lighter mb-5">Our success is measured by your satisfaction, which is why we do our uttermost to provide you with the excellent product.</p>
                        <a className="btn btn-outline-dark btn-xl js-scroll-trigger" href="#about">Find Out More</a>
                    </div>

                </div>
            </div>
            <div className="cover"></div>
        </header>
    );
}

export default MainSection;
