import './SolgySection.scss';

function SolgySection() {
    return (
        <section className="solgy-section bg-white" id="solgy">
            <div className="container">
                <div className="row justify-content-center ">

                    <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 ">
                        <div className="bg"></div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6  offset-lg-1 offset-xl-1 mt-sm-3 mt-3 mt-md-0 mt-lg-0 mt-xl-0" >
                        <h2 className="mr-1 title font-weight-bolder">Solgy TM</h2>

                        <p className="pb-2">Solgy is one of the fastest growing suppliers of spare parts for the European car market,
                            as well as light commercial vehicles. One of the leaders in the market of non-original spare
                            parts in Spain.
                        </p>

                        <p className="pb-2">Brand stands for high-quality automotive spare parts supplied to the independent
                            aftermarket. Solgy is a guarantee of excellent product and service quality.
                        </p>


                        <a className="btn btn-dark btn-xl js-scroll-trigger" href="https://www.solgy.es/">www.solgy.es</a>

                    </div>

                    {/*</div>*/}
                </div>
            </div>
        </section>
    );
}

export default SolgySection;
